import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css';
import config from "./aws-exports";
import Amplify from "aws-amplify";
import store from "./redux/store";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Amplify.configure(config);

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();
