import './App.css';
import {withAuthenticator} from "@aws-amplify/ui-react";
import Navigation from "./components/Navigation/Navigation";
import Camera from "./components/Camera/Camera";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Appointments from "./views/Appointments/Appointments"
import Appointment from "./views/Appointments/Appointment"
import Product from "./views/Product/Product"
import React from "react";
import {Button, createMuiTheme, Dialog, DialogActions, DialogContent, MuiThemeProvider} from "@material-ui/core";
import Overview from "./views/Overview/Overview";
import ProductVisualiser from "./views/Product/ProductVisualiser";
import Products from "./views/Product/Products";
import Settings from "./views/Settings/Settings";

export const CameraDialogContext = React.createContext({
    dialogOpen: false,
    toggleDialog: () => {},
});

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#73e8ff',
            main: '#0086c3',
            dark: '#0086c3',
            contrastText: '#fff',
        },
        secondary: {
            light: '#4f83cc',
            main: '#002f6c',
            dark: '#002f6c',
            contrastText: '#fff',
        },
    },
});

class App extends React.Component{

    state = {
        cameraDialogOpen: false
    }

    openCameraDialog = () => {
        this.setState({cameraDialogOpen : true});
    };

    closeCameraDialog = () => {
        this.setState({cameraDialogOpen : false});
    };

    render() {
        return(
            <MuiThemeProvider theme={theme}>
                <div className="App">
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/camera" component={Camera}/>
                            <Route exact path="/appointments/:appointmentIdentifier" component={Appointment}/>
                            <Route exact path="/appointments" component={Appointments}/>
                            <Route exact path="/products/:productIdentifier/visualiser" component={ProductVisualiser}/>
                            <Route exact path="/products/:productIdentifier" component={Product}/>
                            <Route exact path="/products" component={Products}/>
                            <Route exact path="/settings" component={Settings}/>
                            <Route exact path="/overview" component={Overview}/>
                            <Redirect from="/" to="/overview" />
                        </Switch>
                        <Dialog open={this.state.cameraDialogOpen}>
                            <DialogContent>
                                <Camera closeCamera={this.closeCameraDialog}></Camera>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeCameraDialog} color="primary">
                                    Sluiten
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Navigation openCameraDialog={this.openCameraDialog} closeCameraDialog={this.closeCameraDialog}/>
                    </BrowserRouter>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default withAuthenticator(App);
