import React from 'react'
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import {withStyles} from "@material-ui/core";
import {withRouter} from "react-router";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
});

class Camera extends React.Component {
    constructor(props){
        super(props)
        this.handleScan = this.handleScan.bind(this)
    }
    handleScan(result){
        if(result){
            const publicProductUrl = result.text
            if (publicProductUrl !== undefined && publicProductUrl !== '') {
                var lastIndexOfSlash = publicProductUrl.lastIndexOf('/');
                let productCode = publicProductUrl.substring(lastIndexOfSlash + 1);
                this.props.history.push("/products/" + productCode);
                this.props.closeCamera();
            }
        }
    }
    render() {
        return(
            <BarcodeScannerComponent
                width={"500"}
                onUpdate={(err, result) => {
                    this.handleScan(result)
                }}
            />
        );
    }
}

export default withRouter((withStyles(styles)(Camera)));