import React, {Component} from 'react'

import {Auth} from "aws-amplify";
import {PopoverProps as Toast} from "reactstrap/lib/Popover";
import {Card, CardBody, CardSubtitle, CardText} from 'reactstrap';
import image_placeholder from '../../assets/image_placeholder.jpg'
import {AppBar, Badge, fade, GridList, GridListTile, IconButton, Toolbar, Typography, withStyles, Button} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {productAdded} from "../../redux/actions";
import {connect} from "react-redux";
import {AspectRatio, AssignmentInd, CheckCircleOutline, KeyboardBackspace} from "@material-ui/icons";
import {withRouter} from "react-router";
import {Skeleton} from "@material-ui/lab";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.25),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.35),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '16ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
});

class Product extends Component {

    constructor(props) {
        super(props);
        this.addProductToAppointment = this.addProductToAppointment.bind(this);
        this.visualiseProduct = this.visualiseProduct.bind(this);
        this.state = {
            data: undefined,
            companysettingsSpecifications: undefined,
            isFetching: false
        };
        if (this.props.match.params.productIdentifier !== undefined) {
            this.loadProduct(this.props.match.params.productIdentifier)
        }
    }

    visualiseProduct() {
        this.props.history.push("/products/" + this.state.data.articleNumber + "/visualiser");
    }

    async addProductToAppointment() {
        const session = await Auth.currentSession();
        try {
            let product = {
                articleNumber: this.state.data.articleNumber,
                name: this.state.data.name,
                price: this.state.data.price,
                category: this.state.data.category,
                overviewImage: this.state.data.overviewImage
            }
            const response = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments/${this.props.currentAppointment.appointmentIdentifier}/products`, {
                method: "PATCH",
                body: JSON.stringify(product),
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const updatedAppointment = await response.json();
            this.props.productAdded(updatedAppointment)
        } catch (error) {
            console.log(error);
        }
    }

    async loadProduct(productCode) {
        this.setState({
            isFetching: true
        });
        try {
            const session = await Auth.currentSession();

            let companysettingsSpecifications = this.state.companysettingsSpecifications;
            if (companysettingsSpecifications === undefined) {
                const companysettingsSpecificationsResponse = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings/specifications`, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + session.idToken.jwtToken
                    }
                });
                companysettingsSpecifications = await companysettingsSpecificationsResponse.json();
            }

            const response = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/products/${productCode}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            if (!response.ok) {
                if (response.status === 404) {
                    // TODO werkt nog niet
                    Toast.show({
                        text: "Product niet gevonden!",
                        duration: 3000
                    });
                } else {
                    throw Error(response.statusText);
                }
            }

            const productDataResponse = await response.json();
            this.setState({
                data: productDataResponse,
                companysettingsSpecifications: companysettingsSpecifications,
                isFetching: false
            });
        } catch (error) {
            console.log(error);
        }
    }

    renderSpecifications() {
        const specificationCardItems = [];

        for (var specification of this.state.companysettingsSpecifications) {
            if (specification.categories === undefined || specification.categories.includes(this.state.data.category)) {
                for (var productSpecification of this.state.data.specifications) {
                    if (specification.key === productSpecification.key) {
                        if (specification.type === 'BOOLEAN') {
                            specificationCardItems.push(
                                <CardText>{specification.label} : {productSpecification.value === true ? 'Ja' : 'Nee'}</CardText>
                            )
                        } else {
                            specificationCardItems.push(
                                <CardText>{specification.label} : {productSpecification.value}</CardText>
                            )
                        }
                    }
                }
            }
        }

        return specificationCardItems;
    }

    render() {
        const { classes } = this.props;

        return (
            <>
                <div className={classes.root}>
                    <AppBar position="fixed">
                        <Toolbar>
                            <Button
                                style={{marginLeft: "10Opx"}}
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={() => {
                                    this.props.history.push("/products")
                                }}
                                startIcon={<KeyboardBackspace/>}
                            >
                                Overzicht
                            </Button>
                            <div className={classes.grow}></div>
                            {this.props.currentAppointment !== undefined ?
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    onClick={ () => {this.props.history.push("/appointments/" + this.props.currentAppointment.appointmentIdentifier)}}
                                    color="inherit"
                                    aria-label="open drawer">
                                    {this.props.currentAppointment.products !== undefined ?
                                        <Badge showZero badgeContent={this.props.currentAppointment.products.length} color="secondary">
                                            <AssignmentInd/>
                                        </Badge>
                                        :
                                        <Badge showZero={true} color="secondary">
                                            <AssignmentInd/>
                                        </Badge>
                                    }
                                </IconButton>
                                :
                                <></>
                            }
                        </Toolbar>
                    </AppBar>
                </div>
                <Card>
                    {
                        this.state.data === undefined || this.state.isFetching ?
                            <div style={{marginTop : "70px", marginBottom : "30px"}}>
                                <div style={{marginLeft : "10px"}}>
                                    <Typography variant="h6">
                                        <Skeleton variant="text" width={210} />
                                    </Typography>
                                    <Typography variant="overline">
                                        <Skeleton variant="text" width={320} />
                                    </Typography>
                                </div>
                                <img src={image_placeholder} width={"100%"} alt={"product"}></img>
                            </div>
                            :
                            <div style={{marginTop : "70px", marginBottom : "30px"}}>
                                <div style={{marginLeft : "10px"}}>
                                    <Typography variant="h6">{this.state.data.name}</Typography>
                                    <Typography variant="overline">{this.state.data.articleNumber}</Typography>
                                </div>
                                <img src={this.state.data.overviewImage} width={"100%"} alt={"product"}></img>
                                <CardBody>
                                    <CardText>{this.state.data.price ? this.state.data.price.value : '...'} {'\u20AC'} / {this.state.data.price ? (this.state.data.price.priceType === 'SQUARE_METER' ? '\u33A1' : 'stuk') : '...'}</CardText>
                                    <CardText>
                                        {this.props.currentAppointment !== undefined ?
                                            <>
                                                <Button
                                                    style={{marginLeft: "10Opx"}}
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    onClick={this.addProductToAppointment}
                                                    startIcon={<CheckCircleOutline />}
                                                >
                                                    Toevoegen
                                                </Button>
                                                &nbsp;&nbsp;&nbsp;
                                            </>
                                            :
                                            <></>
                                        }
                                        <Button
                                            style={{marginLeft: "10Opx"}}
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={this.visualiseProduct}
                                            startIcon={<AspectRatio />}
                                        >
                                            Bekijken in visualiser
                                        </Button>
                                    </CardText>
                                    <CardText>{this.state.data.description}</CardText>
                                </CardBody>
                                <CardBody>
                                    <CardSubtitle tag="h6" className="mb-2 text-muted">Specificaties</CardSubtitle>
                                    {this.renderSpecifications()}
                                </CardBody>
                                {this.state.data.images !== undefined && this.state.data.images.length > 0 &&
                                    <>
                                        <CardBody>
                                            <CardSubtitle tag="h6" className="mb-2 text-muted">Sfeerbeelden</CardSubtitle>
                                        </CardBody>
                                        <GridList cellHeight={160} cols={3}>
                                        {this.state.data.images.map((item, i) => (
                                            <GridListTile key={i} cols="1">
                                                <img src={item} alt="other product images"/>
                                            </GridListTile>
                                        ))}
                                        </GridList>
                                    </>
                                }
                            </div>
                    }

                </Card>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    const { currentAppointment } = state.reducer
    return { currentAppointment: currentAppointment }
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({productAdded}, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Product)));