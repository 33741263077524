import React, {Component} from 'react'

import {Auth} from "aws-amplify";
import {AppBar, Badge, fade, GridList, GridListTile, GridListTileBar, IconButton, InputBase, Paper, Toolbar, withStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {productAdded} from "../../redux/actions";
import {connect} from "react-redux";
import {AssignmentInd, Search, Visibility} from "@material-ui/icons";
import {withRouter} from "react-router";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.25),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.35),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '16ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
});

class Products extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            isFetching: false
        };
    }

    componentWillMount(){
        this.loadProducts()
    }

    async loadProducts(articleNumber, articleName) {
        const session = await Auth.currentSession();
        try {
            let fetchProductsUrl = `${process.env.REACT_APP_PURQR_API_BASE_URL}/products`;
            if (articleNumber !== undefined) {
                fetchProductsUrl = `${process.env.REACT_APP_PURQR_API_BASE_URL}/products?articleNumber=${articleNumber}`;
            } else if (articleName !== undefined) {
                fetchProductsUrl = `${process.env.REACT_APP_PURQR_API_BASE_URL}/products?name=${articleName}`;
            }
            const productsResponse = await fetch(fetchProductsUrl, {
                method: "GET",
                headers: {'Authorization': 'Bearer ' + session.idToken.jwtToken}
            });
            if (!productsResponse.ok) {
                throw Error(productsResponse.statusText);
            }
            const productsDataResponse = await productsResponse.json();

            this.setState({
                products: productsDataResponse,
                isFetching: false
            })

        } catch (error) {
            console.log(error);
        }
    }

    searchProduct = (event) => {
        if (event.target.value.length === 15) { // This matches the product identifier RF1561498531211
            this.props.history.push("/products/" + event.target.value);
        } else if (event.target.value.length >= 3) {
            if (event.target.value.startsWith("RF")) { // TODO retrieve from company settings
                // search by article number
                this.loadProducts(event.target.value, undefined);
            } else {
                // search by name
                this.loadProducts(undefined, event.target.value);
            }
        }
    }

    handleSelectProduct(product) {
        this.props.history.push("/products/" + product.articleNumber);
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.root}>
                    <AppBar position="fixed">
                        <Toolbar>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <Search />
                                </div>
                                <InputBase
                                    onChange={this.searchProduct}
                                    placeholder="Product zoeken ..."
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </div>
                            <div className={classes.grow}></div>
                            {this.props.currentAppointment !== undefined ?
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    onClick={ () => {this.props.history.push("/appointments/" + this.props.currentAppointment.appointmentIdentifier)}}
                                    color="inherit"
                                    aria-label="open drawer">
                                    {this.props.currentAppointment.products !== undefined ?
                                        <Badge showZero badgeContent={this.props.currentAppointment.products.length} color="secondary">
                                            <AssignmentInd/>
                                        </Badge>
                                        :
                                        <Badge showZero={true} color="secondary">
                                            <AssignmentInd/>
                                        </Badge>
                                    }
                                </IconButton>
                                :
                                <></>
                            }
                        </Toolbar>
                    </AppBar>
                </div>
                <Paper square style={{marginTop : "70px"}}>
                    <GridList cellHeight={200} >
                        {this.state.products.map((product) => (
                            <GridListTile onClick={() => {this.handleSelectProduct(product)}}>
                                <img src={product.overviewImage} alt=""/>
                                <GridListTileBar
                                    title={product.name}
                                    subtitle={product.articleNumber}
                                    actionIcon={
                                        <IconButton style={{color: 'rgba(255, 255, 255, 0.54)'}}>
                                            <Visibility />
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                </Paper>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    const { currentAppointment } = state.reducer
    return { currentAppointment: currentAppointment }
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({productAdded}, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Products)));