export const startAppointment = appointment => (
  {
    type: 'START_APPOINTMENT',
    payload: appointment,
  }
);

export const fetchAppointments = appointments => (
  {
    type: 'FETCH_APPOINTMENTS',
    payload: appointments,
  }
);

export const selectAppointment = appointment => (
  {
    type: 'SELECT_APPOINTMENT',
    payload: appointment,
  }
);

export const createAppointment = appointment => (
  {
    type: 'CREATE_APPOINTMENT',
    payload: appointment,
  }
);

export const finishAppointment = appointment => (
  {
    type: 'FINISH_APPOINTMENT',
    payload: appointment,
  }
);

export const continueAppointment = appointment => (
  {
    type: 'CONTINUE_APPOINTMENT',
    payload: appointment,
  }
);

export const productAdded = appointment => (
  {
    type: 'PRODUCT_ADDED_TO_APPOINTMENT',
    payload: appointment,
  }
);