import React, {Component} from 'react'
import {AppBar, Button, Toolbar, Typography, withStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {productAdded} from "../../redux/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {ExitToApp} from "@material-ui/icons";
import { Auth } from 'aws-amplify';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

class Settings extends Component {

    constructor(props) {
        super(props);
        this.signOut = this.signOut.bind(this);
        this.state = {
            companysettingUsers: undefined,
            currentUser: undefined,
        }
    }

    componentWillMount(){
        this.fetchCurrentUserDetails();
    }

    async fetchCurrentUserDetails() {
        let userInfo = await Auth.currentUserInfo();
        let companySettingsUsers = await this.loadCompanySettingUsers();
        for (let companySettingsUser of companySettingsUsers) {
            if (companySettingsUser.email === userInfo.username) {
                this.setState({currentUser: companySettingsUser});
            }
        }
    }

    async loadCompanySettingUsers() {
        let companysettingUsers = this.state.companysettingUsers;
        if (companysettingUsers === undefined) {
            const session = await Auth.currentSession();
            const companysettingUsersResponse = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings/users`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            companysettingUsers = await companysettingUsersResponse.json();
        }
        return companysettingUsers;
    }

    async signOut() {
        try {
            await Auth.signOut();
            this.props.history.push("/");
            window.location.reload(false);
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="fixed">
                    <Toolbar></Toolbar>
                </AppBar>
                <div style={{marginTop : "80px", marginBottom : "30px", marginLeft : "30px"}}>
                    <Typography variant="h5" style={{marginBottom: 20}}>
                        Gebruiker
                    </Typography>
                    {this.state.currentUser !== undefined ?
                        <>
                            <Typography variant="h6">{this.state.currentUser.firstName} {this.state.currentUser.lastName}</Typography>
                            <Typography variant="h6">{this.state.currentUser.email}</Typography>
                        </>
                        :
                        <></>
                    }
                    <Typography variant="h5" style={{marginBottom: 20, marginTop: 20}}>
                        Beveiliging
                    </Typography>
                    <Button
                        style={{marginLeft: "10Opx"}}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={this.signOut}
                        startIcon={<ExitToApp />}
                    >
                        Afmelden
                    </Button>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    const { currentAppointment } = state.reducer
    return { currentAppointment: currentAppointment }
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({productAdded}, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Settings)));