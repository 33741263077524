import { combineReducers } from 'redux';

const INITIAL_STATE = {
  currentAppointment: undefined,
  appointments: [],
  plannedAppointments: [],
  pendingAppointments: [],
};

const purqrReducer = (state = INITIAL_STATE, action) => {

  function getAppointmentsByState(appointments, status) {
    let appointmentsForState = [];

    for (let appointment of appointments) {
      if (appointment.status === status) {
        appointmentsForState.push(appointment);
      }
    }
    return appointmentsForState;
  }

  switch (action.type) {
    case 'FETCH_APPOINTMENTS':
      let appointments = action.payload
      return {
        currentAppointment : state.currentAppointment,
        appointments : appointments,
        pendingAppointments: getAppointmentsByState(appointments, "PENDING"),
        plannedAppointments: getAppointmentsByState(appointments, "PLANNED"),
      }
    case 'CREATE_APPOINTMENT':
      return {
        appointments: state.appointments,
        pendingAppointments: getAppointmentsByState(state.appointments, "PENDING"),
        plannedAppointments: getAppointmentsByState(state.appointments, "PLANNED"),
        currentAppointment : action.payload
      };
    case 'CONTINUE_APPOINTMENT':
      return {
        appointments: state.appointments,
        pendingAppointments: getAppointmentsByState(state.appointments, "PENDING"),
        plannedAppointments: getAppointmentsByState(state.appointments, "PLANNED"),
        currentAppointment : action.payload
      };
    case 'START_APPOINTMENT':
      return {
        appointments: state.appointments,
        pendingAppointments: getAppointmentsByState(state.appointments, "PENDING"),
        plannedAppointments: getAppointmentsByState(state.appointments, "PLANNED"),
        currentAppointment : action.payload
      };
    case 'PRODUCT_ADDED_TO_APPOINTMENT':
      for (let appointmentIndex in state.appointments) {
        if (state.appointments[appointmentIndex].appointmentIdentifier === action.payload.appointmentIdentifier) {
          state.appointments[appointmentIndex] = action.payload;
        }
      }
      return {
        appointments: state.appointments,
        pendingAppointments: getAppointmentsByState(state.appointments, "PENDING"),
        plannedAppointments: getAppointmentsByState(state.appointments, "PLANNED"),
        currentAppointment : action.payload
      };
    case 'FINISH_APPOINTMENT':
      return {
        appointments: state.appointments,
        pendingAppointments: getAppointmentsByState(state.appointments, "PENDING"),
        plannedAppointments: getAppointmentsByState(state.appointments, "PLANNED"),
        currentAppointment : undefined
      };

    default:
      return state
  }
};

export default combineReducers({
  reducer: purqrReducer,
});