import React, { Component } from 'react'

import { Auth } from "aws-amplify";
import moment from "moment";
import { withRouter } from "react-router";
import {AssignmentInd, PersonAdd, Search, TodayTwoTone} from '@material-ui/icons'
import PropTypes from 'prop-types';

import {bindActionCreators} from "redux";
import {createAppointment, fetchAppointments, selectAppointment} from "../../redux/actions";
import {connect} from "react-redux";
import {Box, Button, Typography} from "@material-ui/core";

import {AppBar, Avatar, Badge, fade, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemText, Paper, Tab, Tabs, Toolbar, withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    appointmentAvatar: {
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        backgroundColor: theme.palette.secondary.main,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.25),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.35),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '16ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class Appointments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appointments: [],
            plannedAppointments: [],
            pendingAppointments: [],
            tabValue: 0
        };
    }

    componentWillMount(){
        this.loadAppointments()
    }

    async loadAppointments() {
        const session = await Auth.currentSession();
        try {
            const plannedappointmentsResponse = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments?status=PLANNED`, {
                method: "GET",
                headers: {'Authorization': 'Bearer ' + session.idToken.jwtToken}
            });
            if (!plannedappointmentsResponse.ok) {
                throw Error(plannedappointmentsResponse.statusText);
            }
            const plannedAppointmentsDataResponse = await plannedappointmentsResponse.json();

            const pendingappointmentsResponse = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments?status=PENDING`, {
                method: "GET",
                headers: {'Authorization': 'Bearer ' + session.idToken.jwtToken}
            });
            if (!pendingappointmentsResponse.ok) {
                throw Error(pendingappointmentsResponse.statusText);
            }
            const pendingAppointmentsDataResponse = await pendingappointmentsResponse.json();

            const appointments = [].concat(plannedAppointmentsDataResponse, pendingAppointmentsDataResponse)
            this.props.fetchAppointments(appointments)

            this.setState({
                isFetching: false,
            })

        } catch (error) {
            console.log(error);
        }
    }

    selectAppointment(appointment) {
        // this.props.selectAppointment(appointment)
        this.props.history.push("/appointments/" + appointment.appointmentIdentifier)
    }

    createAppointment() {
        let appointment = { customer: {}, seller: {}}
        this.props.createAppointment(appointment)
        this.props.navigation.navigate('Appointment');
    }

    handleChange = (event, tabValue) => {
        this.setState({
            tabValue : tabValue
        });
    }

    render(){
        const { classes } = this.props;

        if (this.props.appointments !== undefined) {
            return (
            <>
                <div className={classes.root}>
                    <AppBar position="fixed">
                        <Toolbar>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <Search />
                                </div>
                                <InputBase
                                    onChange={this.searchProduct}
                                    placeholder="Klant zoeken ..."
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </div>
                            <div className={classes.grow}></div>
                            {this.props.currentAppointment !== undefined ?
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    onClick={ () => {this.props.history.push("/appointments/" + this.props.currentAppointment.appointmentIdentifier)}}
                                    color="inherit"
                                    aria-label="open drawer">
                                    {this.props.currentAppointment.products !== undefined ?
                                        <Badge showZero badgeContent={this.props.currentAppointment.products.length} color="secondary">
                                            <AssignmentInd/>
                                        </Badge>
                                        :
                                        <Badge showZero={true} color="secondary">
                                            <AssignmentInd/>
                                        </Badge>
                                    }
                                </IconButton>
                                :
                                <></>
                            }
                            <Button
                                style={{marginLeft: "10Opx"}}
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={() => {
                                    this.props.history.push("/appointments/create")
                                }}
                                startIcon={<PersonAdd/>}
                            >
                                Nieuwe afspraak
                            </Button>
                        </Toolbar>
                    </AppBar>
                </div>
                <Paper square style={{marginTop : "70px"}}>
                    <Tabs value={this.state.tabValue} indicatorColor="primary" textColor="primary" onChange={this.handleChange}>
                        <Tab label="Actief" value={0} />
                        <Tab label="Gepland" value={1} />
                    </Tabs>
                </Paper>
                <TabPanel value={this.state.tabValue} index={0}>
                    <List style={{marginBottom : "30px"}} >
                        {this.props.pendingAppointments.map((appointment) => (
                            <ListItem key={appointment.appointmentIdentifier} onClick={() => {this.selectAppointment(appointment)}}>
                                <ListItemAvatar>
                                    <Avatar className={classes.appointmentAvatar} >
                                        <TodayTwoTone />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={appointment.customer.name} secondary={moment(appointment.plannedDate).format('DD/MM/YYYY HH:mm') + ' met ' + appointment.seller.firstName + ' ' + appointment.seller.lastName} />
                            </ListItem>
                        ))}
                    </List>
                </TabPanel>
                <TabPanel value={this.state.tabValue} index={1}>
                    <List style={{marginBottom : "30px"}} >
                        {this.props.plannedAppointments.map((appointment) => (
                            <ListItem key={appointment.appointmentIdentifier} onClick={() => {this.selectAppointment(appointment)}}>
                                <ListItemAvatar>
                                    <Avatar className={classes.appointmentAvatar} >
                                        <TodayTwoTone />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={appointment.customer.name} secondary={moment(appointment.plannedDate).format('DD/MM/YYYY HH:mm') + ' met ' + appointment.seller.firstName + ' ' + appointment.seller.lastName} />
                            </ListItem>
                        ))}
                    </List>
                </TabPanel>
            </>
            )
        } else {
            return(
                <></>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const { currentAppointment, plannedAppointments, pendingAppointments, appointments } = state.reducer
    return {
        currentAppointment: currentAppointment,
        plannedAppointments: plannedAppointments,
        pendingAppointments: pendingAppointments,
        appointments: appointments
    }
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({fetchAppointments, selectAppointment, createAppointment}, dispatch)
);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Appointments)));