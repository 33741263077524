import React, {Component} from 'react'
import {Auth} from "aws-amplify";
import {
    AppBar,
    Avatar,
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    GridList,
    GridListTile, GridListTileBar,
    IconButton,
    Toolbar,
    withStyles
} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import {Skeleton} from "@material-ui/lab";
import {bindActionCreators} from "redux";
import {productAdded} from "../../redux/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {
    AssignmentInd,
    Compare,
    CropRotate,
    AspectRatio, CheckCircleOutline,
} from "@material-ui/icons";
import bathroom from '../../assets/scenes/bathroom.png';
import kitchenIslandBlackWood from '../../assets/scenes/kitchen-island-black-wood.png';
import kitchenIslandWhiteMarble from '../../assets/scenes/kitchen-island-white-marble.png';
import livingRoomCarpet from '../../assets/scenes/living-room-carpet.png';
import livingRoom from '../../assets/scenes/living-room.png';

import {ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage} from "react-compare-slider";
import PropTypes from 'prop-types';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    growCenter: {
        flexGrow: 0.8,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    grow: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
});

function ChooseProductDialog(props) {

    const {onClose, onSelect, open} = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogContent dividers>
                <GridList cellHeight={180} style={{width: '500px', height: '450px'}}>
                    {props.appointment !== undefined ?
                        <>
                            {props.appointment.products.map((product) => (
                                <GridListTile key={product.overviewImage} cols={2} style={{width: "50%", height: "184px", padding: "2px"}} onClick={() => onSelect(product)}>
                                    <img src={product.overviewImage} alt=""/>
                                    <GridListTileBar
                                        title={product.name}
                                        subtitle={<span>{product.articleNumber}</span>}
                                        actionIcon={
                                            <IconButton style={{color: 'rgba(255, 255, 255, 0.54)'}}>
                                                <CheckCircleOutline/>
                                            </IconButton>
                                        }
                                    />
                                </GridListTile>
                            ))}
                        </>
                        :
                        <>
                            <GridListTile key={props.product.overviewImage} cols={2} style={{width: "50%", height: "184px", padding: "2px"}} onClick={() => onSelect(props.product)}>
                                <img src={props.product.overviewImage} alt=""/>
                                <GridListTileBar
                                    title={props.product.name}
                                    subtitle={<span>{props.product.articleNumber}</span>}
                                    actionIcon={
                                        <IconButton style={{color: 'rgba(255, 255, 255, 0.54)'}}>
                                            <CheckCircleOutline/>
                                        </IconButton>
                                    }
                                />
                            </GridListTile>
                        </>
                    }
                </GridList>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Sluiten
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ChooseProductDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

function ChooseSceneDialog(props) {

    const {onClose, onSelect, open} = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogContent dividers>
                <GridList cellHeight={180} style={{width: '500px', height: '450px'}}>
                    <GridListTile onClick={() => onSelect('living-room')}>
                        <img src={livingRoom} alt=""/>
                        <GridListTileBar
                            title={"Leefruimte"}
                            subtitle={<span>design</span>}
                            actionIcon={
                                <IconButton style={{color: 'rgba(255, 255, 255, 0.54)'}}>
                                    <CheckCircleOutline/>
                                </IconButton>
                            }
                        />
                    </GridListTile>
                    <GridListTile onClick={() => onSelect('living-room-carpet')}>
                        <img src={livingRoomCarpet} alt=""/>
                        <GridListTileBar
                            title={"Leefruimte"}
                            subtitle={<span>vintage</span>}
                            actionIcon={
                                <IconButton style={{color: 'rgba(255, 255, 255, 0.54)'}}>
                                    <CheckCircleOutline/>
                                </IconButton>
                            }
                        />
                    </GridListTile>
                    <GridListTile onClick={() => onSelect('kitchen-island-black-wood')}>
                        <img src={kitchenIslandBlackWood} alt=""/>
                        <GridListTileBar
                            title={"Keuken"}
                            subtitle={<span>design</span>}
                            actionIcon={
                                <IconButton style={{color: 'rgba(255, 255, 255, 0.54)'}}>
                                    <CheckCircleOutline/>
                                </IconButton>
                            }
                        />
                    </GridListTile>
                    <GridListTile onClick={() => onSelect('kitchen-island-white-marble')}>
                        <img src={kitchenIslandWhiteMarble} alt=""/>
                        <GridListTileBar
                            title={"Keuken"}
                            subtitle={<span>design</span>}
                            actionIcon={
                                <IconButton style={{color: 'rgba(255, 255, 255, 0.54)'}}>
                                    <CheckCircleOutline/>
                                </IconButton>
                            }
                        />
                    </GridListTile>
                    <GridListTile onClick={() => onSelect('bathroom')}>
                        <img src={bathroom} alt=""/>
                        <GridListTileBar
                            title={"Badkamer"}
                            subtitle={<span>design</span>}
                            actionIcon={
                                <IconButton style={{color: 'rgba(255, 255, 255, 0.54)'}}>
                                    <CheckCircleOutline/>
                                </IconButton>
                            }
                        />
                    </GridListTile>
                </GridList>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Sluiten
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ChooseSceneDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

class ProductVisualiser extends Component {

    constructor(props) {
        // TODO constructors are called twice, so make sure the visualisation is not loaded twice
        super(props);
        this.closeSceneSelectionDialog = this.closeSceneSelectionDialog.bind(this);
        this.handleSelectScene = this.handleSelectScene.bind(this);
        this.openSceneSelectionDialog = this.openSceneSelectionDialog.bind(this);
        this.openProductComparisonSelectionDialog = this.openProductComparisonSelectionDialog.bind(this);
        this.closeProductComparisonSelectionDialog = this.closeProductComparisonSelectionDialog.bind(this);
        this.handleSelectProduct = this.handleSelectProduct.bind(this);
        this.toggleComparisonView = this.toggleComparisonView.bind(this);
        this.state = {
            sceneDialogOpen: false,
            productDialogOpen: false,
            isFetching: true,
            productIsFetching: true,
            comparisonViewEnabled: false,
            scene: 'living-room-carpet',
            initialVisualisationUrl: undefined,
            comparisonVisualisationUrl: undefined,
            productIdentifier: this.props.match.params.productIdentifier
        };
        this.loadProductVisualisation()
        this.loadProduct(this.props.match.params.productIdentifier);
    }

    async loadProduct(productCode) {
        this.setState({
            productIsFetching: true
        });
        try {
            const session = await Auth.currentSession();

            const response = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/products/${productCode}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            if (!response.ok) {
                if (response.status === 404) {
                    // TODO werkt nog niet
                    /*
                    Toast.show({
                        text: "Product niet gevonden!",
                        duration: 3000
                    });
                     */
                } else {
                    throw Error(response.statusText);
                }
            }

            const productDataResponse = await response.json();
            this.setState({
                product: productDataResponse,
                comparisonProduct: productDataResponse,
                productIsFetching: false
            });
        } catch (error) {
            console.log(error);
        }
    }

    async loadProductVisualisation() {
        this.setState({
            isFetching: true
        });
        if (this.state.comparisonProduct === undefined) {
            let productCode = this.state.productIdentifier
            try {
                const session = await Auth.currentSession();
                const response = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/product-visualiser/${productCode}?sceneIdentifier=${this.state.scene}`, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + session.idToken.jwtToken
                    }
                });
                const initialVisualisationUrl = await response.text();
                this.setState({
                    initialVisualisationUrl: initialVisualisationUrl,
                    comparisonVisualisationUrl: initialVisualisationUrl,
                    isFetching: false
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            let productCode = this.state.productIdentifier
            try {
                const session = await Auth.currentSession();
                const response = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/product-visualiser/${productCode}?sceneIdentifier=${this.state.scene}`, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + session.idToken.jwtToken
                    }
                });
                const initialVisualisationUrl = await response.text();
                this.setState({
                    initialVisualisationUrl: initialVisualisationUrl,
                    isFetching: false
                });
            } catch (error) {
                console.log(error);
            }
            this.loadComparisonProductVisualisation();
        }
    }

    async loadComparisonProductVisualisation() {
        this.setState({
            isFetching: true
        });
        try {
            const session = await Auth.currentSession();
            const response = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/product-visualiser/${this.state.comparisonProduct.articleNumber}?sceneIdentifier=${this.state.scene}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            const comparisonVisualisationUrl = await response.text();
            this.setState({
                comparisonVisualisationUrl: comparisonVisualisationUrl,
                isFetching: false
            });
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <ChooseSceneDialog open={this.state.sceneDialogOpen} onClose={this.closeSceneSelectionDialog} onSelect={this.handleSelectScene}/>
                {this.state.productIsFetching ?
                    <></>
                    :
                    <ChooseProductDialog product={this.state.product} appointment={this.props.currentAppointment} open={this.state.productDialogOpen}
                                         onClose={this.closeProductComparisonSelectionDialog} onSelect={this.handleSelectProduct}/>
                }
                <AppBar position="fixed" style={{zIndex: 1500}}>
                    <Toolbar>
                        {this.state.productIsFetching ?
                            <Skeleton variant="circle" width={40} height={40}/>
                            :
                            <Avatar className={classes.appointmentAvatar} src={this.state.product.overviewImage}/>
                        }
                        <CropRotate style={{marginLeft: '50px'}}></CropRotate>
                        <Compare style={{marginLeft: '50px'}} onClick={this.toggleComparisonView}></Compare>
                        <AspectRatio style={{marginLeft: '50px'}} onClick={this.openSceneSelectionDialog}></AspectRatio>

                        {this.state.comparisonViewEnabled ?
                            <>
                                <div className={classes.growCenter}></div>
                                <Avatar className={classes.appointmentAvatar}
                                        onClick={this.openProductComparisonSelectionDialog}
                                        src={this.state.comparisonProduct.overviewImage}>
                                </Avatar>
                                <CropRotate style={{marginLeft: '50px'}}></CropRotate>
                            </>
                            :
                            <></>
                        }
                        <div className={classes.grow}></div>
                        {this.props.currentAppointment !== undefined ?
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                onClick={() => {
                                    this.props.history.push("/appointments/" + this.props.currentAppointment.appointmentIdentifier)
                                }}
                                color="inherit"
                                aria-label="open drawer">
                                {this.props.currentAppointment.products !== undefined ?
                                    <Badge showZero badgeContent={this.props.currentAppointment.products.length} color="secondary">
                                        <AssignmentInd/>
                                    </Badge>
                                    :
                                    <Badge showZero={true} color="secondary">
                                        <AssignmentInd/>
                                    </Badge>
                                }
                            </IconButton>
                            :
                            <></>
                        }
                    </Toolbar>
                </AppBar>
                {this.state.initialVisualisationUrl !== undefined && !this.state.isFetching ?
                    <div style={{paddingTop: '60px'}}>
                        <ReactCompareSlider
                            handle={<ReactCompareSliderHandle linesStyle={this.state.comparisonViewEnabled ? {opacity: 1} : {opacity: 0}} buttonStyle={this.state.comparisonViewEnabled ? {
                                backdropFilter: undefined,
                                backgroundColor: 'white',
                                border: 0,
                                boxShadow: undefined,
                                color: '#444'
                            } : {display: 'none'}}/>}
                            itemOne={<ReactCompareSliderImage src={this.state.initialVisualisationUrl}/>}
                            itemTwo={<ReactCompareSliderImage src={this.state.comparisonViewEnabled ? this.state.comparisonVisualisationUrl : this.state.initialVisualisationUrl}/>}
                        />
                    </div>
                    :
                    <div style={{paddingTop: '65px'}}>
                        <LinearProgress/>
                        <Skeleton variant="rect" height={1000}/>
                    </div>
                }
            </div>
        )
    }

    toggleComparisonView() {
        this.setState({
            comparisonViewEnabled: !this.state.comparisonViewEnabled
        })
    }

    closeSceneSelectionDialog() {
        this.setState({
            sceneDialogOpen: false
        })
    }

    handleSelectScene(scene) {
        this.setState({
            scene: scene,
            sceneDialogOpen: false
        })
        this.loadProductVisualisation()
    }

    openSceneSelectionDialog() {
        this.setState({
            sceneDialogOpen: true
        })
    }

    handleSelectProduct(product) {
        this.setState({
            comparisonProduct: product,
            productDialogOpen: false
        })
        this.loadComparisonProductVisualisation();
    }

    closeProductComparisonSelectionDialog() {
        this.setState({
            productDialogOpen: false
        })
    }

    openProductComparisonSelectionDialog() {
        this.setState({
            productDialogOpen: true
        })
    }

}

const mapStateToProps = (state) => {
    const {currentAppointment} = state.reducer
    return {currentAppointment: currentAppointment}
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({productAdded}, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProductVisualiser)));