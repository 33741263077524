import React from 'react';
import {Apps, Today, Settings, CenterFocusStrong, DeveloperBoard} from '@material-ui/icons'
import { Link } from 'react-router-dom';
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";

class Navigation extends React.Component {

    render() {
        return (
            <BottomNavigation className="fixed-bottom" showLabels>
                <BottomNavigationAction label="Overzicht" component={Link} to="/overview" icon={<Apps />} />
                <BottomNavigationAction label="Producten" component={Link} to="/products" icon={<DeveloperBoard />} />
                <BottomNavigationAction onClick={this.props.openCameraDialog} icon={<CenterFocusStrong color={"secondary"} style={{ fontSize: 40 }} />}/>
                <BottomNavigationAction label="Afspraken" component={Link} to="/appointments" icon={<Today />} />
                <BottomNavigationAction label="Instellingen" component={Link} to="/settings" icon={<Settings />} />
            </BottomNavigation>
        )
    }

};

export default Navigation;