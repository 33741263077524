import React, {Component} from "react";
import { Auth } from "aws-amplify";
import moment from "moment";
import {connect} from "react-redux";
import {continueAppointment, finishAppointment, startAppointment} from "../../redux/actions";
import {bindActionCreators} from "redux";
import TextField from "@material-ui/core/TextField";
import { Form } from 'reactstrap';
import {MenuItem, Select, Button, Grid, AppBar, Toolbar, fade, withStyles, Tabs, Tab, Box, Typography, ListItem, ListItemAvatar, Avatar, ListItemText, List} from "@material-ui/core";
import {CheckCircleOutline, DeveloperBoard, KeyboardBackspace, PlayCircleOutline} from "@material-ui/icons";
import PropTypes from "prop-types";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.25),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.35),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    appointmentAvatar: {
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        backgroundColor: theme.palette.secondary.main,
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '16ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class Appointment extends Component {

    constructor(props) {
        super(props);
        this.startAppointment = this.startAppointment.bind(this);
        this.finishAppointment = this.finishAppointment.bind(this);
        this.continueAppointment = this.continueAppointment.bind(this);
        this.createAndStartAppointment = this.createAndStartAppointment.bind(this);
        this.state = {
            errors: {},
            appointment: undefined,
            tabValue: 0,
            sellers: []
        };
        if (this.props.match.params.appointmentIdentifier === 'create') {
            this.state.appointment = {
                customer: {},
                products: [],
                seller: {}
            }
        } else if (this.props.match.params.appointmentIdentifier !== undefined) {
            for (let appointment of this.props.appointments) {
                if (appointment.appointmentIdentifier === this.props.match.params.appointmentIdentifier) {
                    this.state.appointment = appointment;
                }
            }
        }
    }

    componentWillMount() {
        this.loadCompanySettingUsers();
    }

    async loadCompanySettingUsers() {
        // TODO some caching might come in handy here
        let companysettingUsers = this.state.companysettingUsers;
        if (companysettingUsers === undefined) {
            const session = await Auth.currentSession();
            const companysettingUsersResponse = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings/users`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            companysettingUsers = await companysettingUsersResponse.json();
        }

        let sellers = [];
        companysettingUsers.forEach((user) => {
            sellers.push({label: user.firstName + ' ' + user.lastName, value: user.email})
        })

        this.setState({
            sellers: sellers
        });
    }

    async createAndStartAppointment() {

        for (var stateKey of Object.keys(this.state)) {
            if (stateKey.endsWith("Error") && this.state[stateKey] !== "") {
                return;
            }
        }

        const session = await Auth.currentSession();
        this.state.appointment.status = 'PENDING';
        this.state.appointment.lastStateChangeDate = new Date();
        this.state.appointment.plannedDate = this.state.appointment.lastStateChangeDate;
        try {
            const response = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments`, {
                method: "POST",
                body: JSON.stringify(this.state.appointment),
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            let updatedAppointment = await response.json();
            this.props.startAppointment(updatedAppointment)
            this.props.history.push("/products");
        } catch (error) {
            console.log(error);
        }
    }

    async startAppointment() {

        for (var stateKey of Object.keys(this.state)) {
            if (stateKey.endsWith("Error") && this.state[stateKey] !== "") {
                return;
            }
        }

        const session = await Auth.currentSession();
        this.state.appointment.status = 'PENDING';
        this.state.appointment.lastStateChangeDate = new Date();
        try {
            const response = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments/${this.state.appointment.appointmentIdentifier}`, {
                method: "PUT",
                body: JSON.stringify(this.state.appointment),
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            this.props.startAppointment(this.state.appointment)
            this.props.history.push("/products");
        } catch (error) {
            console.log(error);
        }
    }

    async finishAppointment() {
        for (var stateKey of Object.keys(this.state)) {
            if (stateKey.endsWith("Error") && this.state[stateKey] !== "") {
                return;
            }
        }
        const session = await Auth.currentSession();
        this.state.appointment.status = 'FINISHED';
        this.state.appointment.lastStateChangeDate = new Date();
        try {
            const response = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments/${this.state.appointment.appointmentIdentifier}`, {
                method: "PUT",
                body: JSON.stringify(this.state.appointment),
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            this.props.finishAppointment(this.state.appointment)
            this.props.history.push("/appointments");
        } catch (error) {
            console.log(error);
        }
    }

    async continueAppointment() {
        for (var stateKey of Object.keys(this.state)) {
            if (stateKey.endsWith("Error") && this.state[stateKey] !== "") {
                return;
            }
        }
        const session = await Auth.currentSession();
        try {
            const response = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/appointments/${this.state.appointment.appointmentIdentifier}`, {
                method: "PUT",
                body: JSON.stringify(this.state.appointment),
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            this.props.continueAppointment(this.state.appointment)
            this.props.history.push("/products");
        } catch (error) {
            console.log(error);
        }
    }

    showProduct(product) {
        this.props.history.push("/products/" + product.articleNumber);
    }

    handleInputChange(key, event, required) {
        var validationPropery = (key).replace(new RegExp("\\.", 'g'), "") + "Error";
        var value = event.target.value;
        if (required && value === '') {
            this.setState({[validationPropery]: "Verplichte waarde"})
        } else {
            this.setState({[validationPropery]: ""})
        }

        let appointment = this.state.appointment;
        if (key.startsWith("appointment.seller.")) {
            appointment.seller[key.replace("appointment.seller.", "")] = value;
        } else if (key.startsWith("appointment.customer.")) {
            appointment.customer[key.replace("appointment.customer.", "")] = value;
        } else if (key.startsWith("appointment.")) {
            appointment[key.replace("appointment.", "")] = value;
        }
        this.setState({
            appointment: appointment
        })
    }



    handleChange = (event, tabValue) => {
        this.setState({
            tabValue : tabValue
        });
    }

    render() {

        const {classes} = this.props;

        if (this.state.appointment !== undefined) {
            return (
                <>
                    <div className={classes.root}>
                        <AppBar position="fixed">
                            <Toolbar>
                                <Button
                                    style={{marginLeft: "10Opx"}}
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => {
                                        this.props.history.push("/appointments")
                                    }}
                                    startIcon={<KeyboardBackspace/>}
                                >
                                    Overzicht
                                </Button>
                            </Toolbar>
                        </AppBar>
                    </div>

                    <div style={{marginTop : "70px", marginBottom: "105px"}}>
                        <Tabs value={this.state.tabValue} indicatorColor="primary" textColor="primary" onChange={this.handleChange}>
                            <Tab label="Afspraak details" value={0} />
                            <Tab label="Artikels" value={1} />
                        </Tabs>
                        <TabPanel value={this.state.tabValue} index={0}>
                        <div style={{flex: 1, marginBottom: "30px", paddingLeft: 20, paddingRight: 20, alignItems: 'center'}}>
                            <Form>
                                <Typography variant="h6" style={{marginBottom: 20}}>
                                    Klant gegevens
                                </Typography>
                                <TextField
                                    defaultValue=''
                                    fullWidth
                                    value={this.state.appointment.customer.name}
                                    autoCorrect={false}
                                    onChange={(text) => this.handleInputChange("appointment.customer.name", text, true)}
                                    returnKeyType='default'
                                    label='Naam'
                                    labelTextStyle={{color: "black"}}
                                    baseColor={"black"}
                                    tintColor={"black"}
                                    errorColor={"red"}
                                    error={this.state.currentAppointmentcustomernameError}
                                />
                                <TextField
                                    style={{marginTop: 20}}
                                    defaultValue=''
                                    value={this.state.appointment.customer.email}
                                    keyboardType='email-address'
                                    autoCapitalize='none'
                                    fullWidth
                                    autoCorrect={false}
                                    onChange={(text) => this.handleInputChange("appointment.customer.email", text, true)}
                                    returnKeyType='default'
                                    label='Email'
                                    labelTextStyle={{color: "black"}}
                                    baseColor={"black"}
                                    tintColor={"black"}
                                    errorColor={"red"}
                                    error={this.state.currentAppointmentcustomeremailError}
                                />
                                <Typography variant="h6" style={{marginTop: 20, marginBottom: 20}}>
                                    Afspraak gegevens
                                </Typography>
                                <TextField
                                    onChange={(text) => this.handleInputChange("appointment.plannedDate", text, true)}
                                    fullWidth
                                    label='Datum & Tijdstip'
                                    value={moment(this.state.appointment.plannedDate).format('YYYY-MM-DD[T]HH:mm')}
                                    type="datetime-local"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                <TextField style={{marginTop: 20}}
                                           defaultValue=' '
                                           fullWidth
                                           multiline
                                           rows={5}
                                           value={this.state.appointment.remarks}
                                           autoCorrect={false}
                                           onChange={(text) => this.handleInputChange("appointment.remarks", text, true)}
                                           returnKeyType='default'
                                           label='Opmerkingen'
                                           labelTextStyle={{color: "black"}}
                                           baseColor={"black"}
                                           tintColor={"black"}
                                           errorColor={"red"}
                                           error={this.state.currentAppointmentremarksError}
                                />

                                <Typography variant="h6" style={{marginTop: 20, marginBottom: 20}}>
                                    Verkoper
                                </Typography>
                                <Select onChange={(value) => this.handleInputChange("appointment.seller.email", value, false)} value={this.state.appointment.seller.email}>
                                    {this.state.sellers.map((seller) => (
                                        <MenuItem key={seller.value} value={seller.value}>{seller.label}</MenuItem>
                                    ))}
                                </Select>
                            </Form>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.tabValue} index={1}>

                        <List style={{marginBottom : "30px"}} >
                            {this.state.appointment.products.map((product) => (
                                <ListItem onClick={() => {this.showProduct(product)}}>
                                    <ListItemAvatar>
                                        <Avatar className={classes.appointmentAvatar} src={product.overviewImage}  >
                                            <DeveloperBoard />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={product.name} secondary={product.articleNumber} />
                                </ListItem>
                            ))}
                        </List>

                    </TabPanel>
                    <div style={{flex: 1, marginTop: "30px", paddingLeft: 20, paddingRight: 20, alignItems: 'center'}}>
                    {this.state.appointment.status === undefined ?
                        <Button
                            style={{marginTop: 20}}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={this.createAndStartAppointment}
                            startIcon={<PlayCircleOutline/>}
                        >
                            Start afspraak
                        </Button>
                        :
                        <div/>
                    }
                    {this.state.appointment.status === 'PLANNED' ?
                        <Button
                            style={{marginTop: 20}}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={this.startAppointment}
                            startIcon={<PlayCircleOutline/>}
                        >
                            Start afspraak
                        </Button>
                        :
                        <div/>
                    }
                    { this.state.appointment.status === 'PENDING' ?
                        <Grid container style={{marginTop: 60}}>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={this.continueAppointment}
                                    startIcon={<PlayCircleOutline/>}
                                >
                                    Verderzetten
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <div style={{textAlign: 'right', alignContent: 'right'}}>
                                    <Button
                                        style={{marginLeft: "10Opx"}}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={this.finishAppointment}
                                        startIcon={<CheckCircleOutline/>}
                                    >
                                        Afronden
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                        :
                        <div/>
                    }
                    </div>
                    </div>
                </>
            )
        } else {
            return (
                <></>
            );
        }
    }
}

const mapStateToProps = (state) => {
    const { currentAppointment, appointments } = state.reducer
    return {
        currentAppointment: currentAppointment,
        appointments: appointments
    }
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({startAppointment, continueAppointment, finishAppointment}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Appointment));