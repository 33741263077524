// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:7610cfcd-72fb-480c-a77a-faae95a8e1e6",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_XSSVX7uCs",
    "aws_user_pools_web_client_id": "53pe3hs382ti7cjrutnjsoa6s9"
};


export default awsmobile;
