import React, {Component} from 'react'
import {AppBar, Avatar, Badge, IconButton, Paper, Toolbar, Typography, withStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {productAdded} from "../../redux/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {AssignmentInd, Event, LibraryAdd, Today} from "@material-ui/icons";
import {Auth} from "aws-amplify";
import {Skeleton} from "@material-ui/lab";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    item: {
        margin: theme.spacing(1),
        width: theme.spacing(30),
        height: theme.spacing(16),
        paddingTop: "20px",
        padding: "5px",
    },
    blue: {
        color: '#fff',
        backgroundColor: '#26c6da'
    },
    orange: {
        color: '#fff',
        backgroundColor: '#ffa726'
    },
    green: {
        color: '#fff',
        backgroundColor: '#66bb6a'
    }
});

class Overview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companysettingUsers: undefined,
            currentUser: undefined,
            summary: undefined
        }
    }

    componentWillMount(){
        this.fetchCurrentSummary();
        this.fetchCurrentUserDetails();
    }

    async fetchCurrentSummary() {
        try {
            let currentSession = await Auth.currentSession();
            this.setState({session: currentSession});
            let summaryResponse = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/summary`, {
                method: "GET",
                headers: {'Authorization': 'Bearer ' + currentSession.idToken.jwtToken}
            });
            const summaryResponseData = await summaryResponse.json();
            this.setState({summary: summaryResponseData});
        } catch (error) {
            console.log(error);
        }
    }

    async fetchCurrentUserDetails() {
        let userInfo = await Auth.currentUserInfo();
        let companySettingsUsers = await this.loadCompanySettingUsers();
        for (let companySettingsUser of companySettingsUsers) {
            if (companySettingsUser.email === userInfo.username) {
                this.setState({currentUser: companySettingsUser});
            }
        }
    }

    async loadCompanySettingUsers() {
        let companysettingUsers = this.state.companysettingUsers;
        if (companysettingUsers === undefined) {
            const session = await Auth.currentSession();
            const companysettingUsersResponse = await fetch(`${process.env.REACT_APP_PURQR_API_BASE_URL}/companysettings/users`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + session.idToken.jwtToken
                }
            });
            companysettingUsers = await companysettingUsersResponse.json();
        }
        return companysettingUsers;
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="fixed">
                    <Toolbar>
                        <div className={classes.grow}></div>
                        {this.props.currentAppointment !== undefined ?
                            <IconButton
                                edge="start"
                                className={classes.menuButton}
                                onClick={ () => {this.props.history.push("/appointments/" + this.props.currentAppointment.appointmentIdentifier)}}
                                color="inherit"
                                aria-label="open drawer">
                                {this.props.currentAppointment.products !== undefined ?
                                    <Badge showZero badgeContent={this.props.currentAppointment.products.length} color="secondary">
                                        <AssignmentInd/>
                                    </Badge>
                                    :
                                    <Badge showZero={true} color="secondary">
                                        <AssignmentInd/>
                                    </Badge>
                                }
                            </IconButton>
                            :
                            <></>
                        }
                    </Toolbar>
                </AppBar>
                <div style={{marginTop : "80px", marginBottom : "30px"}}>
                    {this.state.currentUser !== undefined ?
                        <Typography style={{marginLeft: "20px"}} variant="h4">Welkom {this.state.currentUser.firstName} {this.state.currentUser.lastName}</Typography>
                        :
                        <Typography style={{marginLeft: "20px", display: "flex"}} variant="h4">Welkom <Skeleton style={{marginLeft: "10px"}} variant="text" width={150} /></Typography>
                    }
                    <Typography style={{marginLeft: "20px"}} variant="h5">Je hebt vandaag</Typography>
                    <div style={{display: "flex", marginTop: "30px"}}>
                        <Paper elevation={5} className={classes.item}>
                            <div style={{display: "flex"}}>
                                <Avatar style={{marginLeft: "10px", marginBottom: "10px"}} className={classes.blue}>
                                    <Today />
                                </Avatar>
                                <Typography style={{marginLeft: "20px", marginTop: "10px"}} variant="body1">Geplande afspraken</Typography>
                            </div>
                            {this.state.summary !== undefined ?
                                <Typography style={{marginLeft: "100px"}} variant="h4">0</Typography>
                                :
                                <Typography style={{marginLeft: "100px"}} variant="h4">
                                    <Skeleton variant="circle" width={40} />
                                </Typography>
                            }
                        </Paper>
                        <Paper elevation={5} className={classes.item}>
                            <div style={{display: "flex"}}>
                                <Avatar style={{marginLeft: "10px", marginBottom: "10px"}} className={classes.orange}>
                                    <Event />
                                </Avatar>
                                <Typography style={{marginLeft: "20px", marginTop: "10px"}} variant="body1">Afgewerkte afspraken</Typography>
                            </div>
                            {this.state.summary !== undefined ?
                                <Typography style={{marginLeft: "100px"}} variant="h4">0</Typography>
                                :
                                <Typography style={{marginLeft: "100px"}} variant="h4">
                                    <Skeleton variant="circle" width={40} />
                                </Typography>
                            }
                        </Paper>
                        <Paper elevation={5} className={classes.item}>
                            <div style={{display: "flex"}}>
                                <Avatar style={{marginLeft: "10px", marginBottom: "10px"}} className={classes.green}>
                                    <LibraryAdd />
                                </Avatar>
                                <Typography style={{marginLeft: "20px", marginTop: "10px"}} variant="body1">Producten gescand</Typography>
                            </div>
                            {this.state.summary !== undefined ?
                                <Typography style={{marginLeft: "100px"}} variant="h4">0</Typography>
                                :
                                <Typography style={{marginLeft: "100px"}} variant="h4">
                                    <Skeleton variant="circle" width={40} />
                                </Typography>
                            }
                        </Paper>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    const { currentAppointment } = state.reducer
    return { currentAppointment: currentAppointment }
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({productAdded}, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Overview)));